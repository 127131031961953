<template>
    <div class="container-fluid">
   <div class="row">
      <div class="col-sm-12">
         <div class="card">
            <div class="card-header d-flex justify-content-between">
               <div class="header-title">
                  <h4 class="card-title">Lista de clientes</h4>
               </div>
            </div>
            <div class="card-body">
               <div class="table-responsive">
                  <div class="row justify-content-between">
                     <div class="col-sm-6 col-md-6">
                        <b-input v-model="filter" placeholder="Pesquisar"></b-input>
                        <br>
                     </div>
                     <div class="col-sm-6 col-md-6 py-2">
                        <div class="list-files d-flex">
                           <router-link class="btn bg-primary" :to="{name: 'app.client-add'}">
                              Adicionar
                           </router-link>
                        </div>
                     </div>
                  </div>
                  <b-table 
                     :items="clients" 
                     :fields="fields"
                     :per-page="perPage"
                     :current-page="currentPage"
                     :filter="filter"
                     @filtered="onFiltered"
                  >
                     <template #cell(Nome) = "{ item }">
                        {{ item.Nome ? item.Nome : item.Cliente ? item.Cliente :'-' }}
                     </template>
                     <template #cell(contribuinte) = "{ item }">
                        {{ item.Contribuinte ? item.Contribuinte : '-' }}
                     </template>
                     <template #cell(morada) = "{ item }">
                        {{ item.Morada ? item.Morada : '-' }}
                     </template>
                     <template #cell(telemóvel) = "{ item }">
                        {{ item.Telemóvel ? item.Telemóvel : '-' }}
                     </template>
                     <template #cell(valor) = "{ item }">
                        {{ item.Valor ? item.Valor +'€' : '0€' }}
                     </template>
                     <template #cell(iva) = "{ item }">
                        {{ item.IVA ? item.IVA : 0 }}
                     </template>
                     <template #cell(facturado) = "{ item }">
                        {{ item.Facturado ? item.Facturado + '€' : 0 }}
                     </template>
                     <template #cell(pago) = "{ item }">
                        {{ item.Pago ? item.Pago + '€' : 0 + '€' }}
                     </template>
                     <template #cell(saldo) = "{ item }">
                        {{ item.Saldo ? item.Saldo + '€' : 0 + '€' }}
                     </template>
                     <template #cell(actions)="{item}">
                        <div class="d-flex align-items-center list-user-action">
                           <router-link class="btn btn-sm bg-primary mr-1" :to="{name: 'app.client-edit', params: {client: item}}">
                              <i class="ri-pencil-line mr-0"></i>
                           </router-link>
                           <a 
                              class="btn btn-sm bg-primary" 
                              @click="deleteItem(item.id)" 
                              data-toggle="tooltip" 
                              data-placement="top" 
                              title=""
                              data-original-title="Delete"
                           >
                              <i class="ri-delete-bin-line mr-0"></i>
                           </a>
                        </div>                     
                        </template>
                  </b-table>             
               </div>
               <div class="row justify-content-between mt-3">
                  <div id="user-list-page-info" class="col-md-6">
                     <span>A apresentar o intervalo {{startIndex + 1}}-{{endIndex > totalRows ? totalRows : endIndex}} de {{totalRows}} registos</span>
                  </div>
                  <div class="col-md-6">
                     <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-end mb-0">
                           <bPagination 
                                :total-rows="totalRows" 
                                v-model="currentPage" 
                                :per-page="perPage" 
                                @input ="updatePage(currentPage)"
                            >
                            </bPagination>
                        </ul>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
export default {
    name:'UserList',
    data() {
        return {
            clients: localStorage.getItem('clients') && Boolean(localStorage.getItem('clients')) ? JSON.parse(localStorage.getItem('clients')) : [],
            firstList: [],
            secondList: [],
            thirdList: [],
            fields: ['Nome', 'Contribuinte', 'Morada', 'Telemóvel', 'Valor', 'IVA', 'Facturado', 'Pago', 'Saldo', {actions: { label: 'Ações'}}],
            perPage: 5,
            currentPage : 1,
            startIndex : 0,
				endIndex : 5,
            totalRows: 1,
            filter: null
        }
    },
    mounted () {
      if(!localStorage.getItem('clients') || Boolean(!localStorage.getItem('clients'))) {
         this.readTextFile('https://wilddourocrm.painelgestao.pt/csv/clientes1.csv', this.firstList);
         this.readTextFile('https://wilddourocrm.painelgestao.pt/csv/Facturacao-por-Cliente 2.csv', this.secondList);
         this.readTextFile('https://wilddourocrm.painelgestao.pt/csv/Saldo-por-Cliente.csv', this.thirdList);
      
         for(let index in this.secondList) {
            this.firstList.some(c => {
               if(c.Nome === this.secondList[index].Nome){
                  c.Valor = this.secondList[index].Valor,
                  c.IVA = this.secondList[index].IVA
               }
            })
         }

         // join the first two lists
         this.clients = this.firstList.concat(this.secondList);

         // compare the third with the array 
         // clients that contains the other two lists
         for(let index in this.thirdList) {
            this.clients.some(c => {
               if(c.Nome === this.thirdList[index].Cliente){
                  c.Facturado = this.thirdList[index].Facturado
                  c.Pago = this.thirdList[index].Pago
                  c.Saldo = this.thirdList[index].Saldo
               }
            })
         }
      }

      this.totalRows = this.clients.length;
    },
    methods: {
      readTextFile(file, list) {
            let rawFile = new XMLHttpRequest();
            rawFile.open("GET", file, false);
            rawFile.onreadystatechange = function ()
            {
                if(rawFile.readyState === 4)
                {
                  if(rawFile.status === 200 || rawFile.status == 0)
                  {
                     let csv = rawFile.responseText;
                     let lines = csv.split('\n');

                     // Get structure of object
                     let headers = lines[0].split(";") 

                     for(let line = 1; line < lines.length; line++){
                        let values = lines[line].split(";");
                        let client = {};

                        for (let index = 0; index < values.length - 1; index++) {
                           let key = headers[index];
                           client[key] = values[index];
                        }
                        client.id = uuidv4();
                        list.push(client);
                     }        
                  }
                }
            }
            rawFile.send(null);
         },
         updatePage : function(activePage) {
            this.currentPage = activePage;
            this.startIndex = (this.currentPage * 5) - 5;
            this.endIndex = this.startIndex + 5;
         },
         onFiltered(filteredItems) {
            // Trigger pagination to update the number of pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
         },
         deleteItem(id) {
            this.clients.splice(this.clients.findIndex(function(i){
               return i.id === id;
            }), 1);
            localStorage.setItem('clients', JSON.stringify(this.clients))
            this.totalRows = this.clients.length;
         }
   },
   watch: {
      clients: function() {
         localStorage.setItem('clients', JSON.stringify(this.clients))
      }
   },
    
}
</script>
<style scoped>
>>>.page-link:focus{
   box-shadow: none;
}
</style>